
import styled from "styled-components";
import { BalanceWrapper, TopBar } from "./TopBarElements";
import twitter_logo from "./assets/twitter_white.svg";
import opensea_logo from "./assets/opensea_white.svg";
// import discord_logo from "./assets/discord_white.svg";

const NavLink = styled.a`
color:white;
font-size: 1.5rem;
font-family: 'Rajdhani';
text-decoration:none;
min-width: 30%;
margin: 0 1rem;
&:hover{
  text-decoration:underline;
}
`
const StyledSocial = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  padding: 0.25rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  
  @media only screen and (max-width: 700px) {
    margin:5px;
  position:relative;
  }
`;
const RightSocials = styled.div`
  display: flex;
  position:absolute;
  right:0;
  top:0;
  margin-right:1rem;
  
  @media only screen and (max-width: 700px) {
    margin-right:0;
  position:relative;
  }
`;

const Image = styled.img`
  width: 100%;
  height:100%;
`;

const links = [
    // { url: "https://playmissionx.com/", title: "MissionX" },
    { url: "https://fizzpowlabs.com/", title: "Home" },
    { url: "https://billionaires.io/", title: "Billionaires" },
    // { url: "https://docs.billionaires.io/", title: "Docs" }
]
const socials = [
    {
        asset: twitter_logo,
        href: "https://twitter.com/NFTBillionaires",
    },
    // {
    //     asset: discord_logo,
    //     href: "https://discord.gg/BillionairesNFT",
    // },
    {
        asset: opensea_logo,
        href: "https://opensea.io/collection/bitcoinbillionaires",
    }
];
const ExternalLinkBar = () => {

    return (
        <TopBar>
            {
                links.map(link => (
                    <BalanceWrapper>
                        <NavLink href={link.url}>{link.title}</NavLink>
                    </BalanceWrapper>
                ))}
            <RightSocials>
                {
                    socials.map(social => (
                        <StyledSocial
                            onClick={() => {
                                (window as any).open(social.href, "_blank")?.focus();
                            }}
                        >
                            <Image src={social.asset} />
                        </StyledSocial>
                    ))
                }
            </RightSocials>
        </TopBar>
    )
}

export default ExternalLinkBar