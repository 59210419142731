import './App.css';
import React from 'react';
import ExternalLinkBar from './ExternalLinkBar';

// const LinkButton = styled.a`
// width: 350px;
// background: linear-gradient(#A12DFF,#22AAEF,#22AAEF);
// margin:20px;
// height:40px;
// padding:10px;
// text-decoration:none;
// color:#282c34;
// border-radius: 20px;
// font-weight:bold;
// text-transform:uppercase;
//   transition: all 0.75s ease;
// &:hover{
// color:white;
//   background: linear-gradient(#A12DFF,#A12DFF,#22AAEF);
// }
// `

const App = () => {
  const logo = require("./logo.png")
  return (
    <div className="App">
      <ExternalLinkBar />
      <header className="App-header">
        <img src={logo} className="App-logo" alt="FizzPow Labs Logo" />
      </header>
    </div>
  );
}

export default App;
