import styled from "styled-components";

export const TopBar = styled.div`
  background: red;
  position: absolute;
  height: 2rem;
  width: 100%;
  z-index: 100;
  background-color: rgba(0,0,0,0.5);
  text-align: center;
  display:flex;
  flex-direction:row;
  justify-content:center;
  max-width: 100%;
  align-items:center;
  @media only screen and (max-width: 700px) {
  flex-direction:column;
  height:unset;
  text-align:center;
  & > div:not(:last-child) {
    border-bottom:2px solid white;
  }
  }

`
export const BalanceWrapper = styled.div`
  color: white;
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  flex-direction:row;
  font-family: 'Digital Counter 7';
`
export const BalanceIcon = styled.img`
  margin-right: 0.5rem;
  width:1.5rem;
  height:1.5rem;
`